import styled from "styled-components";


export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

    @media (max-width: 768px){
       flex-direction: column;
       width: 100%;
       height: 100vh;
       font-size: 1.2rem;
       
    }
`;

export const ContainerLeft = styled.div`
  width: 44%;
  height: 95vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 0.2rem;
  padding-top: 0.5rem;
  margin: 0.2rem;
  border: 1px solid #c8c8c8;
  background-color: #ffff;
   overflow-y: scroll;
   scroll-behaviour: smooth;
  > h2 {
    font-size: 1.0rem;
    margin: 0.2rem;
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px){
    width: 95%;
    height: 100%;
    position: relative;
    border-radius: 25px;

    > h2 {
        font-size: 1.2rem;
        width: 80%;
        margin: 0.2rem;
        margin-right: 25px;
      }
    font-size: 1.0rem;
    margin: 0.8rem;
  }

`;

export const ContainerRight = styled.div`
  width: 35%;
  height: 95vh;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 0.5rem;
  margin: 0.2rem;
  border: 1px solid #c8c8c8;
  background-color: #ffff;
  > svg {
    font-size: 4rem;
    color: white;
    background-color: #3f51b5;
    border-radius: 2rem;
    padding: 0.5rem;
    margin: 1rem;
  }
  > h1 {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

   @media (max-width: 768px){
      width: 95%;
      height: 100%;
      border-radius: 25px;
    }
`;

export const ContainerRadio = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const ContainerConversorPaper = styled.div`
  background-color: white;
  color: #000000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  animation-name: aparicao;
  animation-duration: 0.5s;

  @keyframes aparicao {
    0% {
      opacity: 10%;
    }
    25% {
      opacity: 25%;
    }
    50% {
      opacity: 50%;
    }
    75% {
      opacity: 75%;
    }
    100% {
      opacity: 100%;
    }
  }
`;

export const ContainerHeaderConversor = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  h1 {
    font-size: 44px;
    color: black;
    display: flex;
    align-items: center;
  }
`;

export const RadioButton = styled.input`
  display: flex;
  margin: 10px;
  > label {
    display: flex;
    margin-left: 5px;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 1px;
  margin: 3px;
  align-items: flex-start;
  font-size: 4px;
`;

/* export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`; */

export const Title = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  > svg {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
`;

export const AreaUpload = styled.div`
  padding: 1rem;
  box-sizing: border-box;
  border: 2px dashed #0d8acd;
  border-radius: 1rem;
  min-height: 150px;
  text-align: center;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #c8c8c8;
  position: relative;
  transition: 0.3s all;
  background-color: transparent;
`;
export const UploadFile = styled.label`
  /*  font-size: 1rem; */
`;

export const Text = styled.div`
  font-size: 0.7rem;
`;

export const ListUploads = styled.div`
  z-index: 10;
  text-align: center;
  padding: 3px 5px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  color: black;
  font-size: 12px;
  margin: 10px 0;
  width: 100%;
  position: relative;
`;
export const Input = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  -webkit-appearance: none;
  opacity: 0;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 90%;
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  > button {
    margin: 15px;
  }
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
`;