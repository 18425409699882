import React, { useState } from "react";
import {
  ContainerLeft, ContainerRight, ContainerRadio, ContainerConversorPaper, ContainerHeaderConversor, ContainerInput,
  Container, ContainerButton, Form, Title, Text, AreaUpload, UploadFile, Input
} from "./styles";
import { AiOutlineCloudUpload, AiOutlineFileText } from "react-icons/ai";
import { HiOutlineArrowNarrowLeft, HiOutlineArrowNarrowRight } from "react-icons/hi";
import { Radio, RadioGroup, Button, Snackbar, FormControlLabel, TextField, Divider, List, ListItem } from '@material-ui/core';
import api from "../../Routes/Services/Api/Api";
import MuiAlert from '@material-ui/lab/Alert';
import background from '../../Assets/images/bg_banner.jpg'


function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Conversor = () => {
  const [value, setValue] = React.useState('');
  const [file, setFile] = useState<File>();
  const [open, setOpen] = React.useState(false);
  const [name, setName] = useState('');
  const [codigoEmpresa, setCodigoEmpresa] = useState<string>("");
  const [mostraCodigoEmpresa, setMostraCodigoEmpresa] = React.useState(false);
  const [numeroControleParticipante, setNumeroControleParticipante] = useState<string>("");
  const [mostraNumeroControleParticipante, setMostraNumeroControleParticipante] = React.useState(false);

  const handleChangeRadioButton = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    if ((event.target as HTMLInputElement).value === "remessaItau") {
      setMostraCodigoEmpresa(true);
    } else if ((event.target as HTMLInputElement).value === "remessaItau240") {
      setMostraCodigoEmpresa(true);
    } else if ((event.target as HTMLInputElement).value === "remessaBancoDoBrasil") {
      setMostraCodigoEmpresa(true);
    } else if ((event.target as HTMLInputElement).value === "remessaBancoDoBrasil240") {
        setMostraCodigoEmpresa(true);
    } else if ((event.target as HTMLInputElement).value === "remessaCaixa") {
      setMostraCodigoEmpresa(true);
    }else if ((event.target as HTMLInputElement).value === "remessaSicredi240") {
      setMostraCodigoEmpresa(true);
    }else if ((event.target as HTMLInputElement).value === "remessaBanrisul240") {
      setMostraCodigoEmpresa(true);
    }else {
      setMostraCodigoEmpresa(false);
    }

    if((event.target as HTMLInputElement).value == "remessaSantander"){
      setMostraNumeroControleParticipante(true);
    } else if ((event.target as HTMLInputElement).value === "remessaSantander240") {
        setMostraNumeroControleParticipante(true);
    }
    else {
      setMostraNumeroControleParticipante(false);
    }

  };

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileUpload = e.target.files;
    if (fileUpload) {
      setFile(fileUpload[0]);
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  async function handleClick() {
    if (value === "remessaItau" && codigoEmpresa !== "") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('codigoEmpresa', codigoEmpresa);
        await api.post('conversor/uploadFileRemessaItau', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Remessa_BMP.REM');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor REMESSA ITAÚ");
          });
      }
    }
    else if (value === "retornoItau") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRetornoBmp', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Retorno_Itau.RET');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor RETORNO ITAÚ");
          });
      }
    }
    else if (value === "remessaItau240" && codigoEmpresa !== "") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('codigoEmpresa', codigoEmpresa);
        await api.post('conversor/uploadFileRemessaItau240', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Remessa_BMP.REM');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor REMESSA ITAÚ 240");
          });
      }
    }
    else if (value === "retornoItau240") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRetornoBmpParaItau240', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Retorno_Itau.RET');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor RETORNO ITAÚ");
          });
      }
    }
    else if (value === "remessaBradesco") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRemessaBradescoParaRemessaBmp', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Remessa_Bradesco.REM');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor REMESSA BRADESCO");
          });
      }
    }
    else if (value === "retornoBradesco") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRetornoBmpParaRetornoBradesco', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Retorno_Bradesco.RET');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor RETORNO BRADESCO");
          });
      }
    }
    else if (value === "remessaBradesco240") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRemessaBradesco240ParaRemessaBmp', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Remessa_Bradesco.REM');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor REMESSA BRADESCO 240");
          });
      }
    }
    else if (value === "retornoBradesco240") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRetornoBmpParaRetornoBradesco240', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Retorno_Bradesco.RET');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor RETORNO BRADESCO 240");
          });
      }
    }
    else if (value === "remessaSantander" && numeroControleParticipante !== "") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('numeroControleParticipante', numeroControleParticipante);
        await api.post('conversor/uploadFileRemessaSantanderParaRemessaBmp', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Remessa_BMP.REM');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor REMESSA SANTANDER");
          });
      }
    }
    else if (value === "retornoSantander") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRetornoBmpParaRetornoSantander', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Retorno_Santander.RET');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor RETORNO SANTANDER");
          });
      }
    }
    else if (value === "remessaSantander240" && numeroControleParticipante !== "") {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('numeroControleParticipante', numeroControleParticipante);
            await api.post('conversor/uploadFileRemessaSantander240ParaRemessaBmp', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(res => {
                    const type = res.headers['content-type']
                    const blob = new Blob([res.data])
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = ('Remessa_BMP.REM');
                    link.click()
                    setOpen(true);
                }).catch(() => {
                    alert("Erro ao conectar com a API de conversor REMESSA SANTANDER 240");
                });
        }
    }
    else if (value === "retornoSantander240") {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            await api.post('conversor/uploadFileRetornoBmpParaRetornoSantander240', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(res => {
                    const type = res.headers['content-type']
                    const blob = new Blob([res.data])
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = ('Retorno_Santander.RET');
                    link.click()
                    setOpen(true);
                }).catch(() => {
                    alert("Erro ao conectar com a API de conversor RETORNO SANTANDER 240");
                });
        }
    }
    else if (value === "remessaBancoDoBrasil" && codigoEmpresa !== "") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('codigoEmpresa', codigoEmpresa);
        await api.post('conversor/uploadFileRemessaBBParaRemessaBmp', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Remessa_BMP.REM');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor REMESSA BANCO DO BRASIL");
          });
      }
    }
    else if (value === "retornoBancoDoBrasil") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRetornoBmpParaRetornoBB', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Retorno_Banco_Do_Brasil.RET');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor RETORNO BANCO DO BRASIL");
          });
      }
    }
    else if (value === "remessaBancoDoBrasil240" && codigoEmpresa !== "") {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('codigoEmpresa', codigoEmpresa);
            await api.post('conversor/uploadFileRemessaBB240ParaRemessaBmp', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(res => {
                    const type = res.headers['content-type']
                    const blob = new Blob([res.data])
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = ('Remessa_BMP.REM');
                    link.click()
                    setOpen(true);
                }).catch(() => {
                    alert("Erro ao conectar com a API de conversor REMESSA BANCO DO BRASIL 240");
                });
        }
    }
    else if (value === "retornoBancoDoBrasil240") {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            await api.post('conversor/uploadFileRetornoBmpParaRetornoBB240', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then(res => {
                    const type = res.headers['content-type']
                    const blob = new Blob([res.data])
                    const link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = ('Retorno_Banco_Do_Brasil.RET');
                    link.click()
                    setOpen(true);
                }).catch(() => {
                    alert("Erro ao conectar com a API de conversor RETORNO BANCO DO BRASIL 240");
                });
        }
    }
    else if (value === "remessaCaixa" && codigoEmpresa !== "") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('codigoEmpresa', codigoEmpresa);
        await api.post('conversor/uploadFileRemessaCaixaParaRemessaBmp', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Remessa_BMP.REM');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor REMESSA DO BANCO CAIXA");
          });
      }
    }else if (value === "remessaSicredi240") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRemessaSicredi240ParaRemessaBmp', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Remessa_Sicredi240.REM');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor REMESSA SICREDI 240");
          });
      }
    }
    else if (value === "retornoSicredi240") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRetornoBmpParaRetornoSicredi240', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Retorno_Sicredi240.RET');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor RETORNO SICREDI 240");
          });
      }
    }
    else if (value === "remessaBanrisul240") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRemessaBanrisul240ParaRemessaBmp', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Remessa_Banrisul240.REM');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor REMESSA BANRISUL 240");
          });
      }
    }
    else if (value === "retornoBanrisul240") {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        await api.post('conversor/uploadFileRetornoBmpParaRetornoBanrisul240', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then(res => {
            const type = res.headers['content-type']
            const blob = new Blob([res.data])
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = ('Retorno_Banrisul240.RET');
            link.click()
            setOpen(true);
          }).catch(() => {
            alert("Erro ao conectar com a API de conversor RETORNO BANRISUL 240");
          });
      }
    }
    else {
      alert('Escolha uma opção');
    };
  }


 // const { singOut } = useAuth();

  const inputProps = {
    maxLength: 20,
  };

  const maxNumeroControleParticipante = {
    maxLength: 15,
  };

  return (
    <Container style={{ backgroundImage: 'url(' + background + ')', backgroundSize: 'cover' }}>
      <ContainerLeft>
        <h2> Escolha o Banco para  conversão do CNAB</h2>
        <ContainerRadio>
          <RadioGroup aria-label="gender" onChange={handleChangeRadioButton} style={{ borderBottom: '1px solid #c8c8c8' }} value={value} name="gender1" >
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaItau' control={<Radio style={{ color: '#4876FF' }} />} label={"Remessa Itaú "} />
              <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
            </ListItem>
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoItau' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP " />
              <Title> <HiOutlineArrowNarrowRight /> Retorno Itaú </Title>
            </ListItem>
          </RadioGroup>
          <RadioGroup aria-label="gender" onChange={handleChangeRadioButton} style={{ borderBottom: '1px solid #c8c8c8' }} value={value} name="gender1" >
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaItau240' control={<Radio style={{ color: '#4876FF' }} />} label={"Remessa Itaú 240"} />
              <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
            </ListItem>
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoItau240' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP " />
              <Title> <HiOutlineArrowNarrowRight /> Retorno Itaú 240 </Title>
            </ListItem>
          </RadioGroup>
          <RadioGroup aria-label="gender" onChange={handleChangeRadioButton} style={{ borderBottom: '1px solid #c8c8c8' }} value={value} name="gender1" >
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaBradesco' control={<Radio style={{ color: '#4876FF' }} />} label="Remessa Bradesco " />
              <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
            </ListItem>
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoBradesco' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP " />
              <Title> <HiOutlineArrowNarrowRight /> Retorno Bradesco </Title>
            </ListItem>
          </RadioGroup>
          <RadioGroup aria-label="gender" onChange={handleChangeRadioButton} style={{ borderBottom: '1px solid #c8c8c8' }} value={value} name="gender1" >
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaBradesco240' control={<Radio style={{ color: '#4876FF' }} />} label="Remessa Bradesco 240" />
              <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
            </ListItem>
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoBradesco240' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP" />
              <Title> <HiOutlineArrowNarrowRight /> Retorno Bradesco 240 </Title>
            </ListItem>
          </RadioGroup>
          <RadioGroup aria-label="gender" onChange={handleChangeRadioButton} style={{ borderBottom: '1px solid #c8c8c8' }} value={value} name="gender1" >
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaSantander' control={<Radio style={{ color: '#4876FF' }} />} label="Remessa Santander " />
              <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
            </ListItem>
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoSantander' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP " />
              <Title> <HiOutlineArrowNarrowRight /> Retorno Santander </Title>
            </ListItem>
          </RadioGroup>
            <RadioGroup aria-label="gender" onChange={handleChangeRadioButton} style={{ borderBottom: '1px solid #c8c8c8' }} value={value} name="gender1" >
                <ListItem>
                    <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaSantander240' control={<Radio style={{ color: '#4876FF' }} />} label="Remessa Santander 240" />
                    <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
                </ListItem>
                <ListItem>
                    <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoSantander240' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP " />
                    <Title> <HiOutlineArrowNarrowRight /> Retorno Santander 240</Title>
                </ListItem>
            </RadioGroup>
          <RadioGroup aria-label="gender" onChange={handleChangeRadioButton} style={{ borderBottom: '1px solid #c8c8c8' }} value={value} name="gender1" >
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaBancoDoBrasil' control={<Radio style={{ color: '#4876FF' }} />} label="Remessa Banco do Brasil " />
              <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
            </ListItem>
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoBancoDoBrasil' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP " />
              <Title> <HiOutlineArrowNarrowRight /> Retorno Banco do Brasil </Title>
            </ListItem>
          </RadioGroup>
            <RadioGroup aria-label="gender" onChange={handleChangeRadioButton} style={{ borderBottom: '1px solid #c8c8c8' }} value={value} name="gender1" >
                <ListItem>
                    <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaBancoDoBrasil240' control={<Radio style={{ color: '#4876FF' }} />} label="Remessa Banco do Brasil 240" />
                    <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
                </ListItem>
                <ListItem>
                    <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoBancoDoBrasil240' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP " />
                    <Title> <HiOutlineArrowNarrowRight /> Retorno Banco do Brasil 240</Title>
                </ListItem>
            </RadioGroup> 
          <RadioGroup aria-label="gender" onChange={handleChangeRadioButton} style={{ borderBottom: '1px solid #c8c8c8' }} value={value} name="gender1" >
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaCaixa' control={<Radio style={{ color: '#4876FF' }} />} label="Remessa Caixa " />
              <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
            </ListItem>
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoCaixa' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP " />
              <Title> <HiOutlineArrowNarrowRight /> Retorno Caixa </Title>
            </ListItem>
          </RadioGroup>
          


          <RadioGroup aria-label="gender" onChange={handleChangeRadioButton}  style={{ borderBottom: '1px solid #c8c8c8' }}  value={value} name="gender1" >
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaSicredi240' control={<Radio style={{ color: '#4876FF' }} />} label="Remessa Sicredi240 " />
              <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
            </ListItem>
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoSicredi240' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP " />
              <Title> <HiOutlineArrowNarrowRight /> Retorno Sicredi240 </Title>
            </ListItem>
          </RadioGroup>

          <RadioGroup aria-label="gender" onChange={handleChangeRadioButton} /* style={{ borderBottom: '1px solid #c8c8c8' }} */ value={value} name="gender1" >
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='remessa' value='remessaBanrisul240' control={<Radio style={{ color: '#4876FF' }} />} label="Remessa Banrisul240 " />
              <Title> <HiOutlineArrowNarrowRight /> Remessa BMP </Title>
            </ListItem>
            <ListItem>
              <FormControlLabel style={{ color: 'black' }} id='retorno' value='retornoBanrisul240' control={<Radio style={{ color: '#4876FF' }} />} label="Retorno BMP " />
              <Title> <HiOutlineArrowNarrowRight /> Retorno Banrisul240 </Title>
            </ListItem>
          </RadioGroup>
        </ContainerRadio>
      </ContainerLeft>

      <ContainerRight>
        <AiOutlineFileText />
        <h1>Conversor de CNAB</h1>
        <Form action="..." encType="multipart/form-data" onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}>
          <AreaUpload className="area-upload">
            <UploadFile htmlFor="upload-file" className="label-upload">
              <AiOutlineCloudUpload style={{ fontSize: '4rem' }} />
              <Text className="texto" >{file ? file.name : " Clique ou arraste o arquivo "}</Text>
            </UploadFile>
            <Input type="file" onChange={handleFile} id="upload-file" />
          </AreaUpload>
          <script src="function.js"></script>
          {mostraCodigoEmpresa ?
            <ContainerInput>
              <TextField id="outlined-basic" label="Código Empresa" inputProps={inputProps} type="text" onChange={(e) => setCodigoEmpresa(e.target.value)} />
            </ContainerInput>
            : null}
             {mostraNumeroControleParticipante ?
            <ContainerInput>
              <TextField id="outlined-basic" label="N° Controle Participante" inputProps={maxNumeroControleParticipante} type="text" onChange={(e) => setNumeroControleParticipante(e.target.value)} />
            </ContainerInput>
            : null}
          <ContainerButton>
            <Button variant="contained" color="primary" type="submit">
              Converter
            </Button>
          </ContainerButton>
        </Form>
      </ContainerRight>
    </Container>
  )
}
export default Conversor;