import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// import { useAuth } from './Services/Validation/auth';
import AppRoutes from './app.routes';


 const Routes = () => {

 //  const { logged } = useAuth();

  return (
    <BrowserRouter>
       <AppRoutes />
    </BrowserRouter>
  )
 }

 export default Routes;