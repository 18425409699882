import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    width: 100%;
    height: 100vh;
    background-size: cover;
    font-family :  "Roboto" ;
}

  *,
  *:after,
  *:before {
    box-sizing: inherit;
}

  *, button, input{
    border: 0;
    outline: 0;
}

a:hover{
    cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

p {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding: 0;
    margin: 0;
}


table{
    overflow-x:auto;
}

a{
    text-decoration: none;
}
`;
