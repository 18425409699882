import { Switch, Route, Redirect } from 'react-router-dom';
import Conversor from '../Pages/Conversor/Conversor';



function Routes() {
  return (

    <Switch>
      <Route path="/" exact component={Conversor} />
    </Switch >

  );
}
export default Routes;