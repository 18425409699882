/* eslint-disable import/no-anonymous-default-export */
export default {
  title: "global",

  colors: {
    primary: "#546ffc",
    secondary: "#363740",
    tertiary: "#b2befa",

    white: "#fff",
    black: "#000",
    grey: "#F3F2EF",

    success: "#009933",
    info: "#f7931b",
    warning: "#e44c4e",
  },
};
