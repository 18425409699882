import React from 'react';
import Routes from './Routes';
import GlobalStyles from './Styles/GlobalStyles';
import '@fontsource/roboto';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import global from './Styles/Theme/themeGlobal';

function App() {
  return (
    <ThemeProvider theme={global}>
    <SnackbarProvider maxSnack={3} >
         <GlobalStyles />
         <Routes />
    </SnackbarProvider>
    </ThemeProvider>

  );
}

export default App;
